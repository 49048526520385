import React from 'react'
import Layout from '../components/Layout';
import {Section} from '../components/Section';
import { graphql } from 'gatsby';
import Seo from "../components/Seo";


class InfoPage  extends React.Component {
   componentDidMount() {
        const pictures = document.querySelectorAll("picture");

        const preloadImage = (picture) => {
            let source = picture.childNodes[0];
            let image  = picture.childNodes[1];
            source.srcset = source.dataset.srcset;
            image.srcset = image.dataset.srcset;
            image.src = image.dataset.src;
            image.style.display = "block";
        };
    
        if(typeof IntersectionObserver !== "undefined") {
            setTimeout(() => {
                const config = {
                    // If the image gets within 50px in the Y axis, start the download.
                    rootMargin: '50% 0%',
                    threshold: 0.01
                };
    
                const onIntersection = (entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0) {
                            observer.unobserve(entry.target);
                            preloadImage(entry.target);
                        }
                    });
                };
    
                let observer = new IntersectionObserver(onIntersection, config);
    
                pictures.forEach(picture => {
                    observer.observe(picture);
                });
            },1);
        } else {
            pictures.forEach(picture => {
                preloadImage(picture);
            });
        }
    }

    render() {
        const images = this.props.data.allImageSharp.edges.reduce((ac,image)=>{
            const imageName = image.node.fluid.originalName.split(".")[0];
            ac[imageName] = image.node;
            return ac;
        },{});
    
        return (
            <Layout location={this.props.location}>
                <Seo 
                    pathname={this.props.path} 
                    titleSuffix={" | Servicios"}
                    image={images["services-book-02"].fluid.src}
                    description={"Servicios ofrecidos por el fotógrafo profesional Gabriel Jürgens, basado en Buenos Aires, Argentina"}
                    aditionalKeywords={"Servicios, Presupuestos, Armado de catálogos, Fotografía de producto, Books profesionales, Eventos, Tasaciones, Bancos de Imágenes, Consultoria, Información en general"}
                />
                <div style={{paddingTop: "55px"}}>
                    <Section title={"Servicios"}/>
                    <div className={"serviceContainer"}>
                        <h3>Fotografia de producto</h3>
                        <div className={"servicePictureContainer"} style={{
                            backgroundImage: `url(${images["services-product-03"].fluid.base64})`,
                            paddingBottom: `${images["services-product-03"].original.height/images["services-product-03"].original.width*48}%`,
                            width:"48%",
                            float:"left",
                            marginRight:"2%"
    
                        }}>
                            <picture>
                                <source data-srcset={images["services-product-03"].fluid.srcSetWebp} sizes={images["services-product-03"].fluid.sizes} type={"image/webp"}/>
                                <img className={"servicePicture"} data-src={images["services-product-03"].fluid.src} data-srcset={images["services-product-03"].fluid.srcSet}
                                 sizes={"75vw"} data-sizes={images["services-product-03"].fluid.sizes} alt={"Gabriel Jürgens"}/>
                            </picture>
                        </div>
                        <div className={"servicePictureContainer"} style={{
                            backgroundImage: `url(${images["services-product-01"].fluid.base64})`,
                            paddingBottom: `${images["services-product-01"].original.height/images["services-product-01"].original.width*48}%`,
                            width:"48%",
                            float:"right",
                            clear:"right",
                            marginLeft:"2%"
    
                        }}>
                            <picture>
                                <source data-srcset={images["services-product-01"].fluid.srcSetWebp} sizes={images["services-product-01"].fluid.sizes} type={"image/webp"}/>
                                <img className={"servicePicture"} data-src={images["services-product-01"].fluid.src} data-srcset={images["services-product-01"].fluid.srcSet}
                                 sizes={"75vw"} data-sizes={images["services-product-01"].fluid.sizes} alt={"Gabriel Jürgens"}/>
                            </picture>
                        </div>
    
                        <p>
                            Realizamos trabajos profesionales en estudio y locaciones. Nuestro foco está en lograr resaltar
                            los detalles y características distintivas de los productos retratados, logrando imágenes
                            atractivas que los potencien y diferencien del resto.
                        </p>
                        <p>
                            Contamos con equipo profesional de iluminación, lentes para fotografia macro, carpas de producto
                            diseñadas para evitar reflejos molestos en elementos reflectantes como joyas y cromados, mesa de
                            producto con fondos infinitos y para máscaras de recorte.
                        </p>
                        <div className={"servicePictureContainer"} style={{
                            backgroundImage: `url(${images["services-product-02"].fluid.base64})`,
                            paddingBottom: `${images["services-product-02"].original.height/images["services-product-02"].original.width*100}%`,
                            width:"100%"
    
                        }}>
                            <picture>
                                <source data-srcset={images["services-product-02"].fluid.srcSetWebp} sizes={images["services-product-02"].fluid.sizes} type={"image/webp"}/>
                                <img className={"servicePicture"} data-src={images["services-product-02"].fluid.src} data-srcset={images["services-product-02"].fluid.srcSet}
                                 sizes={"75vw"} data-sizes={images["services-product-02"].fluid.sizes} alt={"Gabriel Jürgens"}/>
                            </picture>
                        </div>
                        <p>
                            Si bien buscamos la toma perfecta al momento de la captura, también nos encargamos de los
                            procesos de edición y post producción digital, donde corregimos imperfecciones del producto o de
                            la toma, entregando imágenes listas para ser usadas.
                        </p>
                    </div>
    
                    <div className={"serviceContainer"}>
                        <h3>Books Profesionales</h3>
                        <div className={"servicePictureContainer"} style={{
                            backgroundImage: `url(${images["services-book-01"].fluid.base64})`,
                            paddingBottom: `${images["services-book-01"].original.height/images["services-book-01"].original.width*100}%`,
                            width:"100%"
    
                        }}>
                            <picture>
                                <source data-srcset={images["services-book-01"].fluid.srcSetWebp} sizes={images["services-book-01"].fluid.sizes} type={"image/webp"}/>
                                <img className={"servicePicture"} data-src={images["services-book-01"].fluid.src} data-srcset={images["services-book-01"].fluid.srcSet}
                                 sizes={"75vw"} data-sizes={images["services-book-01"].fluid.sizes} alt={"Gabriel Jürgens"}/>
                            </picture>
                        </div>
    
                        <p>
                            Realizamos books profesionales para modelos, actrices, actores, novias, cumpleaños de 15, etc. 
                            Las tomas se pueden hacer en estudio y locaciones, tanto con luz natural como artificial.
                        </p>
                        <p>
                            Contamos con una red de contactos para facilitar servicios de maquillaje y asesoria de vesturario.
                        </p>
                        <div className={"servicePictureContainer"} style={{
                            backgroundImage: `url(${images["services-book-02"].fluid.base64})`,
                            paddingBottom: `${images["services-book-02"].original.height/images["services-book-02"].original.width*100}%`,
                            width:"100%"
    
                        }}>
                            <picture>
                                <source data-srcset={images["services-book-02"].fluid.srcSetWebp} sizes={images["services-book-02"].fluid.sizes} type={"image/webp"}/>
                                <img className={"servicePicture"} data-src={images["services-book-02"].fluid.src} data-srcset={images["services-book-02"].fluid.srcSet}
                                 sizes={"75vw"} data-sizes={images["services-book-02"].fluid.sizes} alt={"Gabriel Jürgens"}/>
                            </picture>
                        </div>
                        <p>
                            Los books incluyen los procesos de edición y post producción, y contamos con amplia experiencia 
                            en procesos de retoque fotográfico en Photoshop para moda y contenido editorial.
                        </p>
                    </div>
    
                </div>
            </Layout>
        )    
    }

}


export default InfoPage


export const HomeQuery = graphql`
    query InfoPage {
        allImageSharp(filter: {fields: {data: {sourceInstanceName: {eq: "images"}}}, fluid: {originalName:{regex: "/services-.+-.+/"}}}) {
            edges {
                node {
                    fluid(quality: 80) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                    }
                    original {
                        width
                        height
                    }
                }
            }
        }
    }
`;
